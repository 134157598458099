import Link from 'next/link';
import React from "react"

const Footer = () => {

    let date = new Date()

    return <>

        <div className='w-full bg-white text-xs text-gray-600 mt-auto'>
            <div className='flex items-center justify-between mx-auto max-w-7xl px-4 py-5'>
                <div>©{date.getFullYear()} <Link className='underline hover:no-underline' href='https://universaanalytics.com/' target='_blank'>Universa Analytics</Link>. All Rights Reserved.</div>
                <div className='flex gap-4'>
                    <Link href='https://bernoullirisk.com/privacy/'>Privacy Policy</Link>
                </div>
            </div>
        </div>
    </>
}

export default Footer;