import '@/styles/globals.css';
import '@/styles/multiRangeSlider.css'

import { Provider } from 'react-redux';
import Head from 'next/head';

import { store } from '@/services/redux/store';
import Navbar from '@/components/Navbar';
import Footer from '../components/Footer';

export default function App({ Component, pageProps }) {
  return (
    <>
    <Head>
        <title>Cost Effective Risk Mitigation</title>
      </Head>
      <Provider store={store}>
        <Navbar />
        <Component {...pageProps} />
        <Footer />
      </Provider>
    </>
  )
}