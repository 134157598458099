import React, { useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import orgImg from '../../public/UA_Logo.svg';
import { isLoggedIn, logout, loggedUserDetails } from '../services/auth'
import  {setUser}  from '@/services/redux/userSlicer';
import { CallAPI } from '@/services/utils'

const Navbar = () => {
    const userLogged = isLoggedIn();
    const router = useRouter();
    const dispatch = useDispatch();
    const path = router.asPath

    const { username, role } = loggedUserDetails();

    useEffect(() => {
        verifyUser()
    }, [])

    const verifyUser = async () => {
        try {

            const verify = await CallAPI(
                '/user/verify-user',
                'GET',
                {},
                // data
            )
            // if(verify.message === 'success'){
            setLoggedInUser(verify);
            path ? router.push(path) : router.push('/home');
            // }
        } catch (error) {
            // console.log('Error while verifying user', error);
        }
    }

    const setLoggedInUser = (userObj) => {
        dispatch(setUser(userObj))
    }
    const handleLogout = async (event) => {
        event.preventDefault();
        await logout();
        setLoggedInUser(null);
        router.push(process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_LOCAL_MAIN_DOMAIN : 'https://bernoullirisk.com')

    }

    return <>
        <nav className="bg-white border-gray-200 shadow-md shadow-slate-300 relative">
            <div className="max-w-7xl w-full flex flex-wrap items-center justify-between mx-auto py-5 px-4">
                <Link href="https://bernoullirisk.com/home/" className="flex items-center">
                    <Image
                        src={orgImg}
                        width="150"
                        // height="30"
                        // layout="responsive"
                        alt="Picture of the author"
                    />
                    <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white ml-2"></span>
                </Link>
                <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="capitalize font-normal flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
                        {userLogged ?
                            <>
                                <li>
                                    <Link href={process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? `${process.env.NEXT_PUBLIC_LOCAL_MAIN_DOMAIN}/home/` : 'https://bernoullirisk.com/home/'} className="block py-2 pl-3 pr-4 text-gray-700 rounded md:bg-transparent md:p-0" aria-current="page">Home</Link>
                                </li>
                                {username && <li>
                                    <p className="block py-2 pl-3 pr-4 text-gray-700 rounded md:bg-transparent md:p-0" aria-current="page">Hi {username.charAt(0).toUpperCase() + username.slice(1)}!</p>
                                </li>}
                                <li>
                                    <Link href="/" onClick={handleLogout} className="block py-2 pl-3 pr-4 text-gray-700 rounded md:bg-transparent md:p-0" aria-current="page">Logout</Link>
                                </li>
                            </>
                            :
                            <>
                                <li>
                                    <Link href={process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? `${process.env.NEXT_PUBLIC_LOCAL_MAIN_DOMAIN}` : 'https://bernoullirisk.com'} className="block py-2 pl-3 pr-4 text-gray-700 rounded md:bg-transparent md:p-0" aria-current="page">Login</Link>
                                </li>
                            </>
                        }

                        {/* <li>
                            <Link href="/" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Home</Link>
                        </li> */}
                        {/* <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
                        </li>
                        <li>
                            <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    </>
}

export default Navbar;