import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlicer';

export const store = configureStore({
    reducer: {
        userDetails: userSlice.reducer
    },

    //for security purposes this should not be enabled in production
    devTools: process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? true : false,
})

