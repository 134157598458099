import axios from 'axios';

// How to Use:
// CallAPI('user/signup', 'POST', {}, { username: 'a', password: 'b' });

const CallAPI = async (apiUrl, method, headers, data = null, params = null) => {
    try {
        const response = await axios({
            method: method,
            url: apiUrl,
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
            data: data,
            withCredentials: true,
            // params: params,
            baseURL: process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_DEV_URL : 'https://api.bernoullirisk.com/api',
        });
        // console.log('🚀 ~ file: utils.js:25 ~ CallAPI ~ response:', response);
        return response.data;
    } catch (error) {
        console.error('Error in API', error);
        throw error;
    }
};

module.exports = {
    CallAPI
}