import { useSelector } from 'react-redux';

import { CallAPI } from './utils.js';

const login = async (username, password) => {
    let data = {
        username,
        password
    }
    try {
        const performLogin = await CallAPI(
            'user/login',
            'POST',
            {},
            data,
        );

        // const performLogin = await axios.post('http://localhost:4000/api/user/login', {
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     data: data,
        //     withCredentials: true,
        // });



        return performLogin;
    } catch (error) {
        // console.log(error);
        throw error
    }

}

const register = async () => {
    try {

    } catch (error) {

    }
}

const logout = async () => {
    try {
        const performLogout = await CallAPI(
            'user/logout',
            'GET',
            {},
            // data,
        );
        return performLogout.message === 'success' ? true : false
    } catch (error) {
        throw Error('Error while logout', error)
    }
}

const isLoggedIn = () => {
    const {user} = useSelector(state => state.userDetails);
    const {message} = user || {}
    if (message === 'success' && user != null) {
        return true
    } else {
        return false
    }
}

const loggedUserDetails = () => {
    const {user} = useSelector(state => state.userDetails);
    const {role, email, username, message} = user || {}
    return {username, email, role, message}
}


module.exports = {
    login,
    register,
    logout,
    isLoggedIn,
    loggedUserDetails 
}